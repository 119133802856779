<template>
  <div>
    <p
      class="mt-12"
      style="color: #35507b; font-size: 16px"
    >
      {{ $t('modulos.certificados.checklist.checklist') }}
    </p>
    <tabela-padrao
      sem-acoes
      :mostrar-checkbox="false"
      :dados="checklistAssinatura"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
    >
      <template v-slot:item.ok="{ item }">
        <input-radio
          style="height: 100%"
          :divider="false"
          :options="opcoes"
          @input="(v) => (item.item.ok = v)"
        />
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
export default {
  props: {
    checklistAssinatura: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        colunas: [
          {
            value: 'ok',
            text: '',
            sortable: false,
          },
          {
            value: 'descricao',
            text: 'Descricao',
            sortable: false,
          },
          {
            value: 'acaoNaoOk',
            text: 'Ação Não Ok',
            sortable: false,
          },
        ],
      },
      opcoes: [
        { text: this.$t('modulos.certificados.checklist.opcoes.ok'), value: true },
        { text: this.$t('modulos.certificados.checklist.opcoes.naoOk'), value: false },
      ],
    };
  },
};
</script>
