<template>
  <div>
    <div class="mb-2">
      <div class="titulo-pagina">
        {{
          $t('modulos.certificados.titulos.visualizar', { codigo: codigoOS })
        }}
      </div>
    </div>
    <div
      :class="{
        'flex flex-row row mt-4 justify-space-between': $route.query.assinar,
      }"
      style="width: 100vw"
    >
      <div
        v-if="$route.query.assinar"
        class="div-dados-assinar-kus872 px-4"
      >
        <RequisitosCalibracao :requisitos-calibracao="requisitosCalibracao" />
        <Checklists :checklist-assinatura="checklistAssinatura" />
      </div>
      <stimulsoft-visualizador ref="visualizador" />
    </div>
    <div class="mt-6">
      <v-divider />
    </div>
    <div class="d-flex justify-end pr-4 mt-2">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao
        v-if="$route.query.assinar"
        class="mr-2"
        :tooltip="tooltipAssinar"
        :disabled="!podeAssinar"
        @click="assinarCertificado"
      >
        {{ $t('modulos.certificados.botoes.assinar_certificado') }}
      </botao-padrao>
      <botao-padrao
        v-if="$route.query.assinar"
        color="primary-error-600"
        outlined
        :disabled="!podeCancelar"
        @click="cancelarCalibracao"
      >
        {{ $t('modulos.certificados.botoes.cancelar_certificado') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import Stimulsoft from 'stimulsoft-reports-js/Scripts/stimulsoft.reports';
import { StimulsoftVisualizador } from '@components/misc';
import CertificadoService from '@common/services/cadastros/CertificadoService';
import ChecklistService from '@common/services/cadastros/ChecklistService';
import ProcedimentoEletronicoService from '@common/services/cadastros/ProcedimentoEletronicoService';
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import Checklists from './certificado/Checklists.vue';
import RequisitosCalibracao from './certificado/RequisitosCalibracao.vue';

export default {
  components: {
    StimulsoftVisualizador,
    Checklists,
    RequisitosCalibracao,
  },
  props: ['id'],
  data() {
    return {
      form: null,
      resultadosTabelas: null,
      variaveisRelatorio: null,
      requisitosCalibracao: [],
      checklistAssinatura: [],
    };
  },
  computed: {
    codigoOS: function () {
      if (!this.form) return '...';
      return this.form.dataSource.calibracao.codigoCertificado;
    },
    podeAssinar: function () {
      if (!this.form) return false;
      return (
        this.form.situacaoCertificado == 'PendenteAssinatura' &&
        this.form.flagAssinar &&
        this.checklistAssinatura.every((item) => item.ok)
      );
    },
    tooltipAssinar: function () {
      if (!this.form) return '';
      if (!this.checklistAssinatura.every((item) => item.ok))
        return this.$t('modulos.certificados.tooltip_checklist');
      if (!this.form.flagAssinar)
        return this.$t('modulos.certificados.tooltip');
      return '';
    },
    podeCancelar: function () {
      if (!this.form) return false;
      return this.form.flagCancelar;
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.certificados.titulos.visualizar_certificado')
    );
    this.abrirStimusSoft();
  },
  methods: {
    buscarChecklists: async function () {
      if (!this.form.tipoInstrumentoId || !this.$route.query.assinar) return;
      ChecklistService.buscarByTipoInstrumentoAndTipoCheckList(
        this.form.tipoInstrumentoId,
        'AssinaturaCertificado'
      )
        .then((res) => {
          res.data.itens.forEach((item) => (item.ok = false));
          this.checklistAssinatura = res.data.itens;
        })
        .catch(() => {
          this.toastAlerta('Não foi encontrado checklist para assinatura');
        });
    },
    buscarRequisitosCalibracao() {
      if (!this.form.clienteId) return;
      ParticipanteService.buscaRequisitosParticipante(this.form.clienteId).then(
        (res) => {
          this.requisitosCalibracao = res.data.find(
            (el) => el.tipoRequisito == 'Calibracao'
          )?.requisitos;
        }
      );
    },
    voltar: function () {
      this.$router.push({
        name: this.$route.query?.retorno || 'certificado',
        params: { id: this.$route.query?.instrumentoRetorno || null },
      });
    },
    assinarCertificado: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.confirmar_assinar')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.assinarCertificado(this.id)
          .then((res) => {
            this.toastSucesso(this.$t(`modulos.certificados.assinado_sucesso`));
            // this.abrirStimusSoft();
            this.verificarRedirecionamentoAoAssinar(res.data);
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    verificarRedirecionamentoAoAssinar(response) {
      const url = `/cadastro/certificado/${response.certificadoId}/avaliacao`;
      const redirect = () => this.$router.push(url);
      if (response.situacaoAvaliacao === 'Reprovado') return redirect();
      if (
        response.situacaoAvaliacao === 'NaoValidado' &&
        [
          'ContaComCriterioCliente',
          'ContaComCriterioClienteOuGenerico',
        ].includes(response.validacaoCertificado)
      )
        return redirect();
      this.voltar();
    },
    cancelarCalibracao: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.confirmar_cancelar')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.cancelarCalibracao(this.id)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.certificados.cancelado_sucesso`)
            );
            this.abrirStimusSoft();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    carregarRelatorio: function (arquivoJson) {
      let report = new Stimulsoft.Stimulsoft.Report.StiReport();

      if (arquivoJson) {
        let ehStringJson = this.ehStringJson(arquivoJson);
        let arq = arquivoJson;
        if (ehStringJson) arq = JSON.parse(arquivoJson);
        report.load(arq);
      }

      let dataSet = new Stimulsoft.Stimulsoft.System.Data.DataSet('KeL');
      dataSet.readJson(this.dataSource);
      report.regData(dataSet.dataSetName, '', dataSet);
      report.dictionary.synchronize();

      return report;
    },
    ehStringJson(str) {
      try {
        var json = JSON.parse(str);
        return typeof json === 'string';
      } catch (e) {
        return false;
      }
    },
    filtrarValoresPorColunas: function (colunas, valores) {
      return valores.filter((valor) =>
        colunas.some((coluna) => coluna.id === valor.tabelaColunaId)
      );
    },
    abrirStimusSoft: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoService.buscarCertificado(this.id)
        .then((res) => {
          console.clear();
          this.form = res.data;
          let variaveisRelatorio = this.carregarRelatorio(res.data.certificado);
          let relatorioPossuiParametros =
            variaveisRelatorio.dictionary.variables.list.length > 0;
          if (relatorioPossuiParametros) {
            this.variaveisRelatorio =
              variaveisRelatorio.dictionary.variables.list.map((variavel) => {
                return variavel.val;
              });
          }

          setTimeout(() => {
            this.$refs.visualizador.carregarDataSource(this.form.dataSource);
            //this.buscarCertificado(this.form.dataSource.id);
            this.buscarCertificado(
              res.data.dataSource.procedimentoEletronico.id
            );
          }, 500);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.$route.query.assinar) {
            this.buscarRequisitosCalibracao();
            this.buscarChecklists();
          }
        });
    },
    montarComponente: function (data) {
      this.$refs.visualizador.montarComponente(
        data.certificado,
        data.situacaoCertificado == 'Assinado' ? '' : data.situacaoCertificado
      );
    },
    buscarCertificado: function (id) {
      ProcedimentoEletronicoService.buscarCertificado(id, false)
        .then((res) => {
          this.montarComponente(res.data);
        })
        .catch(() => {
          this.montarComponente();
        });
    },
    buscarValoresTabela: function (tabela, resultadosTabelas, dataSource) {
      return CalibracaoService.buscarTabelaCalibracao(
        dataSource.calibracao.id,
        tabela.id
      )
        .then((res) => {
          resultadosTabelas[tabela.nome] = {
            colunas: res.data.colunas,
            linhas: res.data.linhas,
          };
        })
        .catch(() => {
          console.log('erro ao buscar tabela com nome:', tabela.nome);
        });
    },
    buscarTabelas: async function (dataSource) {
      let resultadosTabelas = [];
      let promessasBuscaTabelas = [];

      dataSource.calibracao.tabelas.forEach((tabela) => {
        promessasBuscaTabelas.push(
          this.buscarValoresTabela(tabela, resultadosTabelas, dataSource)
        );
      });

      return await Promise.all(promessasBuscaTabelas)
        .then(() => {
          return resultadosTabelas;
        })
        .catch(() => {});
    },
    exportar: function () {
      this.$refs.visualizador.exportarPDF();
    },
  },
};
</script>

<style>
.div-dados-assinar-kus872 {
  width: calc(100vw - 900px);
  height: 100vh;
}
</style>
