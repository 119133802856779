<template>
  <div>
    <p style="color: #35507b; font-size: 16px">
      {{
        $t('modulos.certificados.requisitos.requisitos_participante_calibracao')
      }}
    </p>
    <div class="rounded-lg div-requisitos-ka72n">
      <div v-for="(item, index) in requisitosCalibracaoAgrupado" :key="index">
        <pre class="mt-1 mt-3">{{
          item.classificacaoRequisito | formataRequisitoNome
        }}</pre>
        <ul class="requisito-descricao">
          <li v-for="(linha, idx) in item.descricao.split('\n')" :key="idx">
            {{ formataRequisitoDescricao(item, idx, linha) }}
          </li>
        </ul>
      </div>

      <span v-if="!requisitosCalibracao.length">
        {{ $t('modulos.certificados.requisitos.sem_requisitos') }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    requisitosCalibracao: {
      type: Array,
      default: () => [],
    },
  },

  filters: {
    formataRequisitoNome(value) {
      const mapping = {
        TextoLivre: 'Requisitos Textos',
        TipoDocumento: 'Requisitos Tipos Documentos',
        CampoAdicional: 'Requisitos Campos Adicionais',
      };

      return mapping[value];
    },
  },

  methods: {
    formataRequisitoDescricao(item, idx, linha) {
      return item.classificacaoRequisito !== 'TextoLivre'
        ? `${idx + 1} - ${linha.trim()}`
        : linha.trim();
    },
  },

  computed: {
    requisitosCalibracaoAgrupado() {
      return this.requisitosCalibracao.reduce((acc, currentItem) => {
        const existingItem = acc.find(
          (item) =>
            item.classificacaoRequisito === currentItem.classificacaoRequisito
        );

        if (existingItem)
          existingItem.descricao += `\n${currentItem.descricao}`;
        else acc.push({ ...currentItem });

        return acc;
      }, []);
    },
  },
};
</script>
<style>
.div-requisitos-ka72n {
  width: 100%;
  border: 1px solid #e4e7ec;
  background-color: #f9fafb;
  color: #98a2b3;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 10px;
}
.requisito-descricao {
  list-style-type: none;
}
</style>
